import PropTypes from 'prop-types';
import { Container } from 'components/primitives/grid';
import { renderRows } from 'components/containers';
import HeroEffectLayout from './HeroEffectLayout';

const ContentPage = ({ header, content }) => (
  <>
    <HeroEffectLayout />
    {header != null ? <Container><h1>{header}</h1></Container> : null}
    {renderRows(content)}
  </>
);

ContentPage.propTypes = {
  header: PropTypes.string,
  content: PropTypes.array,
};

export default ContentPage;
